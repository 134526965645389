.featured_card {
  width: 80%;
  margin: 0 auto;
}

#Layer_1 {
  width: 65%;
}

.card_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  margin: 0 auto;
  border-radius: 50px;
  background: #ebebff;
}

.card_header img {
  width: fit-content;
  height: fit-content;
}

.card_body p {
  text-align: left;
}

.card_body h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
}

.first-row {
  margin: 35px auto;
  padding: 70px 0 70px 0;
}

.first-row h3 {
  font-size: 35px;
  font-weight: bold;
  line-height: 58px;
}

.second-first-row {
  padding-top: 70px;
}

.second-first-row h3 {
  font-size: 35px;
  font-weight: bold;
  line-height: 58px;
}

.second-row {
  width: 55%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 70px;
}

.second-col h3 {
  font-size: 35px;
  line-height: 58px;
  font-weight: bold;
  text-align: center;
}

.mail {
  width: 267px;
  height: 49px;
  border-radius: 8px;
  justify-content: center;
  background-color: #3e3dc5;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 35px auto;
}

.mail-btn {
  font-size: 20px;
  color: white;
  text-decoration: none;
  font-family: "Nexa-Text", sans-serif;
}

.mail-btn:hover {
  color: white;
}

.social-icons a {
  padding: 15px;
  font-size: 25px;
}

.social-icons a svg {
  background-color: #ebebff;
  color: #3e3dc5;
  padding: 15px;
  border-radius: 40px;
  width: 25px;
}

@media screen and (max-width: 996px) {
  .header {
    width: 100%;
    height: unset;
    padding-top: 10px;
  }

  .header_wrapper {
    width: 100%;
    margin-top: 10px;
  }

  .header_wrapper>* {
    margin-top: unset;
  }

  .card_header {
    width: 35%;
  }

  .card_header img {
    /* padding: 20px; */
  }

  .second-row {
    width: 100%;
  }

  .head_info a img {
    width: 44%;
    margin-top: unset;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 556px) {
  .first-row h3 {
    font-size: 25px;
    line-height: unset;
  }

  .second-first-row h3 {
    font-size: 25px;
    line-height: unset;
  }

  .card_header img {
    padding: unset;
  }

  .second-row {
    width: 100%;
  }

  .second-col h3 {
    font-size: 25px;
    line-height: unset;
  }

  .mail-btn {
    font-size: 17px;
  }

  .social-icons a svg {
    width: 15px;
    padding: 15px;
  }

  .social-icons a {
    padding: 10px;
    font-size: unset;
  }
}

@media screen and (max-width:1024px) {
  .header {
    width: 100%;
  }

}