@font-face {
  font-family: "Nexa";
  src: url(/src/assets/fonts/Nexa-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nexa-Text";
  src: url(/src/assets/fonts/NexaText-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "Nexa-Text", serif;
  font-weight: 700;
}

h2 {
  font-family: "Nexa-Text", serif;
  font-weight: 700;
}

h3 {
  font-family: "Nexa-Text", serif;
  font-weight: 700;
}

h4 {
  font-family: "Nexa-Text", serif;
  font-weight: 700;
}

h5 {
  font-family: "Nexa-Text", serif;
  font-weight: 700;
}

h6 {
  font-family: "Nexa-Text", serif;
  font-weight: 700;
}

p {
  font-family: "Nexa", serif;
  font-weight: 400;
}

._modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
}

.overlay {
  background: #000000;
  opacity: 0.6;
}

.custom-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  /* background: #ffffff; */
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 100%;
  min-width: 300px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  z-index: 101;
}

.custom-modal-content svg{
  padding: 20px;
}


.active-modal{
  overflow: hidden;
}