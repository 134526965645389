.footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* align-content: center; */
    /* justify-content: center; */

    width: 100%;
    height: 100%;
    /* height: 50px; */
    background-color: #3E3DC5;
    color: white;
    font-size: 15px;
    font-family: 'Nexa', sans-serif;
    padding: 35px;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-links a {
    padding: 0 10px 0 10px;
    color: white;
}

.footer span a {
    padding-bottom: 20px;
    text-decoration: none;
    color: white;
}

.footer span a:hover {
    color: white;
}

@media screen and (max-width:1280px) {
    .footer-links {
        flex-direction: column;
        
    }

}