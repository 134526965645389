/*Loading Animation*/
.loadingAnimation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1001;
}

.moneta-face img {
  width: 100%;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  animation: coinFlip 1.1s 0s linear infinite;
}

@keyframes coinFlip {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: rotate3d(0, 1, 0, 270deg);
  }
  100% {
    transform: translateY(10px);
  }
}
