/* .header {
  width: 100%;
  height: 87vh;
  padding-top: 100px;
} */
.header{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cls-1 {
  fill: #3e3dc5;
}
.samsung-wrapper {
  padding-right: 35px;
}
.header_wrapper {
  width: 80%;
  margin: 0 auto;
}
.head_info a img {
  margin-top: 50px;
}
.overlap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /*width: 80%;*/
  margin: 0 auto;
  max-width: 400px;
}

.front-image {
  height: 670px;
  object-fit: cover;
  align-self: center;
  transform: translate(120px, 20px);
}

.back-image {
  height: 650px;
  object-fit: cover;
  align-self: center;
  grid-column: 1 / 3;
}

.head_info {
  padding-top: 50px;
}
.head_info h3 {
  font-size: 40px;
  font-weight: bold;
}
.head_info p {
  font-size: 18px;
  line-height: 29px;
}

@media screen and (max-width: 996px) {
  .overlap {
    margin: 50px auto;
  }
  .front-image {
    height: 400px;
    transform: translate(65px, 10px);
  }
  .back-image {
    transform: translateX(50px);
    height: 400px;
  }
}

@media screen and (max-width: 556px) {
  .front-image {
    height: 300px;
    transform: translate(10px, 10px);
  }
  .back-image {
    transform: translateX(50px);
    height: 300px;
  }
  .head_info h3 {
    font-size: 30px;
  }
}

@media screen and(max-width:1024) {
  .samsung-wrapper {
    padding-right: unset;
  }
}
